import React from 'react';
import { PiWhatsappLogoFill } from 'react-icons/pi';
import { BsTelephoneOutboundFill } from 'react-icons/bs';

const Quickcontact = () => {
    return (
        <div className="fixed right-5 xl:right-10 bottom-20 z-50" >
            <div >
                <div className='text-5xl flex flex-col'>
                    <a href="https://wa.me/+917404013931/?text=Hello%20there!%20I'm%20interested%20in%20learning%20more%20about%20your%20courses.%20Could%20you%20please%20provide%20me%20with%20some%20information%3F"  ><span className='my-3 bg-white rounded-full p-[3px] animate-bounce inline-block'><PiWhatsappLogoFill className='text-green-500' /></span></a>
                    <a href="tel:+919996333931"> <span className='my-3 bg-white rounded-full p-3 animate-bounce inline-block text-3xl'><BsTelephoneOutboundFill className='text-blue-700 ' /></span></a>
                </div>
            </div>
        </div>
    )
}

export default Quickcontact