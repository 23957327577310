import React from 'react';
import { BiLoaderCircle } from 'react-icons/bi'

const Loader = () => {
    return (
        <div className='fixed top-0 left-0 w-full h-full z-50 bg-blue-950/50 '>
            <div className="flex justify-center items-center h-screen  ">
                <div className="loader ease-linear  text-9xl animate-spin    ">
                    <BiLoaderCircle />
                </div>
            </div>
        </div>
    )
}

export default Loader