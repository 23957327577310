import React from 'react';
import mailicon from '../../images/mailicon.png';
import phoneicon from '../../images/phoneicon.png';
import locationicon from '../../images/locationicon.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    const quikLinks = [{ text: "who we are", hrf: "/aboutus" }, { text: "our specializations", hrf: "/" }, { text: "IELTS", hrf: "/iletscoaching" }, { text: "PTE", hrf: "/ptecoaching" }, { text: "our branches", hrf: "/ourbranches" }, { text: "contact us", hrf: "/contactus" }];

    const studyVisaLinks = [{ text: "study in australia ", hrf: "/studyinaustralia" }, { text: "study in canada", hrf: "/studyincanada" }, { text: "study in uk", hrf: "/studyinuk" }, { text: "study in usa", hrf: "/studyinusa" }, { text: "study in new zealand", hrf: "/studynewzealand" }, { text: "study in europe", hrf: "/studyeurope" }];

    const ourOffices = [{ text: "SHOP NO. 105, HUDA COMPLEX , NEAR DRDA JIND-126102 ", head: "HEAD OFFICE", icon: locationicon, hrf: "" }, { text: "dsoverseasjind@Gmail.com", icon: mailicon, hrf: "" }, { icon: phoneicon, text: "8708960008, 9996333931, 9812137073", hrf: "" }, { text: "POONAM CINEMA MARKET, NEAR BUS STAND GOHANA-131301", head: "BRANCH OFFICE", hrf: "", icon: locationicon }, { text: "dsoverseasgohana@Gmail.com", hrf: "", icon: mailicon }, { text: "8708960008, 9588163164, 9416827346", icon: phoneicon, hrf: "" }];
    // 
    return (
        <section className='bg-light-blue'>
            <div className="container px-5 pt-12 pb-5 lg:pt-16 max-w-7xl mx-auto">
                <div className='capitalize lg:flex justify-between'>
                    <div className='flex justify-between xl:w-5/12'>
                        <div className='my-10 sm:w-[200px]'>
                            <span className='text-lg sm:text-xl font-bold '>quick links</span>
                            <ul className='ml-3 mt-5'>
                                {quikLinks.map((item) => <Link to={item.hrf}> <li className='footerList relative my-3 hover:underline'>{item.text}</li></Link>)}
                            </ul>
                        </div>
                        <div className=' my-10 sm:w-[200px]'>
                            <span className='text-lg sm:text-xl font-bold '>study visa</span>
                            <ul className='ml-3 mt-5'>
                                {studyVisaLinks.map((item) => <Link to={item.hrf}> <li className='footerList relative my-3 hover:underline'>{item.text}</li></Link>)}
                            </ul>
                        </div>
                    </div>
                    <div className='sm:flex justify-between  xl:w-5/12'>
                        <div className='max-w-xs my-10' >
                            <span className='text-lg sm:text-xl font-bold'>our offices</span>
                            <ul className='mt-5'>
                                {ourOffices.map((item) => <li className='my-5 relative ml-10'>
                                    <span className='absolute -left-10 -top-[6px]  '><img src={item.icon} alt="" /></span>
                                    <span className={`font-bold ${item.head ? "block" : "hidden"}`}>{item.head}</span>
                                    {item.text}
                                </li>)}
                            </ul>
                        </div>
                        <div className='my-10 sm:max-w-[200px]'>
                            <span className='text-lg sm:text-xl font-bold '>business hours</span>
                            <p className=' mt-5'>monday to saturday
                                09:30 AM to 6:00 PM
                                sunday closed
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <p className='text-center py-3'>@copyright2023</p>
        </section>
    )
}

export default Footer