import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/common/Navbar';
import Quickcontact from './Components/common/Quickcontact';
import Footer from './Components/common/Footer';
import Loader from './Loader';
import StudyEurope from './Components/studyvisa/StudyEurope';
import { Helmet } from 'react-helmet';

const About = lazy(() => import('./Components/About/About'));
const Home = lazy(() => import('./Components/Home/Home'));
const Pr = lazy(() => import('./Components/Pr/Pr'));
const StudyAustralia = lazy(() => import('./Components/studyvisa/StudyAustralia'));
const StudyCanada = lazy(() => import('./Components/studyvisa/StudyCanada'));
const StudyUk = lazy(() => import('./Components/studyvisa/StudyUk'));
const StudyUsa = lazy(() => import('./Components/studyvisa/StudyUsa'));
const StudyNewZealand = lazy(() => import('./Components/studyvisa/StudyNewZealand'));
const IletsCoaching = lazy(() => import('./Components/coaching/IletsCoaching'));
const PteCoaching = lazy(() => import('./Components/coaching/PteCoaching'));
const Ourbranches = lazy(() => import('./Components/ourbranches/Ourbranches'));
const Contactus = lazy(() => import('./Components/contactus/Contactus'));
const TouristVisa = lazy(() => import('./Components/touristvisa/TouristVisa'));

function App() {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DS Overseas Jind</title>
        <link rel="canonical" href="https://dsoverseasjind.com" />
      </Helmet>
      <Router>
        <Navbar />
        <Quickcontact />
        {/* <Loader /> */}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/studyinaustralia" element={<StudyAustralia />} />
            <Route path="/studyincanada" element={<StudyCanada />} />
            <Route path="/studyinuk" element={<StudyUk />} />
            <Route path="/studyinusa" element={<StudyUsa />} />
            <Route path="/studynewzealand" element={<StudyNewZealand />} />
            <Route path="/studyeurope" element={<StudyEurope />} />
            <Route path="/touristvisa" element={<TouristVisa />} />
            <Route path="/iletscoaching" element={<IletsCoaching />} />
            <Route path="/ptecoaching" element={<PteCoaching />} />
            <Route path="/pr" element={<Pr />} />
            <Route path="/ourbranches" element={<Ourbranches />} />
            <Route path="/contactus" element={<Contactus />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </>
  );
}

export default App;
