import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../images/logo.png'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <section className='bgblue z-50 fixed top-0 left-0 w-full'>
                <div className='container px-5  max-w-7xl mx-auto z-50'>
                    <nav className="py-4 md:py-2 flex justify-between items-center font-bold">
                        <div className="flex items-center">
                            <Link onClick={() => setIsOpen(false)} to={'/'}> <img src={logo} alt="Logo" className="h-10 w-10 md:h-14 md:w-14 lg:h-16 lg:w-16 xl:w-28 xl:h-[112px] mr-4" /></Link>
                        </div>
                        <div className="hidden lg:flex xl:space-x-10 space-x-7 items-center">
                            <Link to={'/'}><span className="hover:underline ">Home</span></Link>
                            <Link to={'/aboutus'}><span className="hover:underline ">About Us</span></Link>
                            <span className="relative inline-block group cursor-pointer ">
                                Study Visa
                                <div className="hidden group-hover:block absolute left-0 mt-0 py-2 w-40 bgblue">
                                    <Link to={"/studyinaustralia"}> <span className="navBarLinkbg">Study In Australia</span> </Link>
                                    <Link to={"/studyincanada"}> <span className="navBarLinkbg">Study In Canada</span> </Link>
                                    <Link to={"/studynewzealand"}> <span className="navBarLinkbg">Study In New Zealand</span> </Link>
                                    <Link to={"/studyinuk"}> <span className="navBarLinkbg">Study In UK</span> </Link>
                                    <Link to={"/studyinusa"}> <span className="navBarLinkbg">Study In USA</span> </Link>
                                    <Link to={"/studyeurope"}> <span className="navBarLinkbg">Study In Europe</span> </Link>
                                </div>
                            </span>
                            <Link to={'/touristvisa'}><span className="hover:underline">Tourist Visa</span></Link>
                            <span className="relative inline-block group">
                                Coaching
                                <div className="hidden group-hover:block absolute left-0 mt-0 py-2 w-40 bgblue">
                                    <Link to={"/iletscoaching"}>  <span className="navBarLink">IELTS Coaching</span></Link>
                                    <Link to={"/ptecoaching"}>  <span className="navBarLink">PTE Coaching</span></Link>
                                </div>
                            </span>
                            <Link to={'/pr'}><span className="hover:underline">PR</span></Link>
                            <Link to="/ourbranches"><span className="hover:underline">Our Branches</span></Link>
                            <Link to='/contactus'> <button className='uppercase hover:underline '>contact us</button> </Link>
                        </div>
                        <div className="lg:hidden">
                            {isOpen ? (
                                <button className="text-white text-2xl" onClick={toggleSidebar}>
                                    <FaTimes />
                                </button>
                            ) : (
                                <button className="text-white text-2xl" onClick={toggleSidebar}>
                                    <FaBars />
                                </button>
                            )}
                        </div>
                        <div
                            className={`lg:hidden transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'
                                } fixed inset-y-0 right-0 w-1/2 p-4 bgblue `}
                        >
                            <button className="text-white mb-4 text-2xl" onClick={toggleSidebar}>
                                <FaTimes />
                            </button>
                            <div className="flex flex-col space-y-4 duration-300 transition-all ">
                                <Link onClick={toggleSidebar} to={'/'}><span className="navBarLink mt-5 -mb-3">Home</span></Link>
                                <Link onClick={toggleSidebar} to={'/aboutus'}><span className="navBarLink">About Us</span></Link>
                                <Link><span className="pl-4 relative inline-block group">
                                    Study Visa
                                    <div className="hidden group-hover:block    py-2 w-40 bgblue">
                                        <Link onClick={toggleSidebar} to={"/studyinaustralia"}> <span className="navBarLink">Study In Australia</span> </Link>
                                        <Link onClick={toggleSidebar} to={"/studyincanada"}> <span className="navBarLink">Study In Canada</span> </Link>
                                        <Link onClick={toggleSidebar} to={"/studynewzealand"}> <span className="navBarLink">Study In New Zealand</span> </Link>
                                        <Link onClick={toggleSidebar} to={"/studyinuk"}> <span className="navBarLink">Study In UK</span> </Link>
                                        <Link onClick={toggleSidebar} to={"/studyinusa"}> <span className="navBarLink">Study In USA</span> </Link>
                                        <Link onClick={toggleSidebar} to={"/studyeurope"}> <span className="navBarLink">Study In Europe</span> </Link>
                                    </div>
                                </span></Link>
                                <Link onClick={toggleSidebar} to={'/touristvisa'}><span className="navBarLink">Tourist Visa</span></Link>
                                <span className="pl-4 relative inline-block group">
                                    Coaching
                                    <div className="hidden group-hover:block py-2 bgblue">
                                        <Link onClick={toggleSidebar} to={"/iletscoaching"}>  <span className="navBarLink">IELTS Coaching</span></Link>
                                        <Link onClick={toggleSidebar} to={"/ptecoaching"}>  <span className="navBarLink">PTE Coaching</span></Link>
                                    </div>
                                </span>
                                <Link onClick={toggleSidebar} to={'/pr'}><span className="navBarLink">PR</span></Link>
                                <Link onClick={toggleSidebar} to='/ourbranches'><span className="navBarLink">Our Branches</span></Link>
                                <Link onClick={toggleSidebar} to='/contactus'> <button className='uppercase   py-2 px-5 font-bold hover:bg-white duration-300 btnTextHover'>contact us</button> </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>
        </>
    );
};

export default Navbar;
